/* ==========================================================================
   Base styling
   ========================================================================== */


body{

    @media (max-width:767px) {

        background: @bg-body;
    }
}

h1, h2, h3{

    margin-top: 50px;
    letter-spacing: 1px;
}

ul:not(.plone-toolbar-main) li, ol li, dt, dd, heading{

    font-size: 1.25em;
    line-height: 1.6;
    color: #000;
}

li li{

    font-size: 1em;
}
.documentFirstHeading{

    text-align: center;
    border-bottom: none;
    padding-bottom: 0;
    margin-top: 30px;
}

.discreet{

    font-weight: 400 !important;
}

// static banner
.banner{

    background-image: url('../images/slide-1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 530px !important;
}

// remove https link glyph
i.link-https:after{
    content: "" !important;
}

// stop image overflow
figure.image-product img{
    width: 100%;
    height: 100%;
}

// events
.template-event_listing .documentFirstHeading nav{

    position: inherit !important;
    bottom: 0 !important;
}