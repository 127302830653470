/* ==========================================================================
   VARIABLES
   ========================================================================== */




/* Font family */
@font-josefin: "Josefin Slab","Helvetica Neue",Helvetica,Arial,sans-serif;
@font-opensans:   "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;

/* Colors */
@white:         #fff;
@black:         #000;
@black-light:   #1b1c1d;
@gray-darker:   #444;
@gray:          #999999;
@gray-light:    #cccccc;

/* text */
@text-success:  #42a35a;
@text-info:     #3085d6;
@text-warning:  #644405;
@text-error:    #ca3838;

/* Background colors */
@bg-body:           fade(@white, 90%);
@bg-footer:         fade(@white, 90%);
@bg-portlet-header: @white;

/* Font colors */
@color-portlet-header:  @black;
@color-portlet-link:    @black;
@color-base-link:       #337ab7;
@color-base-link-hover: #23527c;


/* alert */
@info:              @text-info;
@warning:           #f6b83f;
@error:             @text-error;
@success:           @text-success;

/* buttons */
@button-context:           @success;
@button-standalone:        @black;
@button-destructive:       @error;
@button-default:           @black-light;