/* ==========================================================================
   BREADCRUMBS
   ========================================================================== */





.plone-breadcrumb{

    margin-top: 0;
    background-color: @bg-body;
    box-shadow: 0px 2px 4px rgba(204, 204, 204, 0.31);
    font-family: @font-josefin;
}

