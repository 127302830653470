/* ==========================================================================
   PORTLETS
   ========================================================================== */





/* DEFAULT STYLING FOR ALL PORTLETS
   ========================================================================== */

aside {

    section.portlet {

        margin-bottom: 20px;
        border: none;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
        border-radius: 0;

        /* portlet header */
        .portletHeader{

            font-size: 16px;
            letter-spacing: 1px;
            color: @color-portlet-header;
            background: @bg-body;
            padding: 5px;
            text-align: center;
            // border-bottom: 2px solid @black;

            a{
                color: @color-portlet-header;
                // padding-bottom: 5px;

                &:hover,
                &:focus{

                    color: @gray-darker;
                    text-decoration: none;
                }
            }
        }

        /* portlet item and content */
        .portletContent{

            border-top: 0;
            padding: 0 5px;
        }

        .portletItem{

            a{
                color: @color-portlet-link;
                padding: 10px 5px 25px 5px;
                font-family: @font-opensans;
                font-size: 15px;
                line-height: 1.5;

                &:before{

                    content: "/2014";
                    position: absolute;
                    left: 12px;
                }

                &:hover,
                &:focus{

                    color: @black;
                    text-decoration: none;
                }
            }

            .portletItemDetails{
                padding: 5px;
                line-height: 1.5;
            }
        }

        .portletFooter{
            font-family: @font-opensans;
        }
    }
}


/* News, RSS, Events, Recent portlets
   ========================================================================== */

aside{

    .portletNews,
    .portletRss,
    .portletEvents,
    .portletRecent{

        section.portletContent{

            ul{

                li{

                    a{
                        color: @color-portlet-link;

                        &:before{

                            content: "";
                            position: absolute;
                            left: 12px;
                            font-size: inherit;
                            margin-top: 0;
                            color: @color-portlet-link;
                        }
                    }
                }
            }

        }
    }

}

/* NavigationTree Portlet
   ========================================================================== */

.portletNavigationTree{

    border: 0 !important;
    border-radius: 5px !important;
    box-shadow: none !important;

    .portletContent{

        background-color: @bg-body;

        ul.navTree{

            list-style: none;

            li.navTreeItem{

                a{
                    background-color: transparent;
                    font-size: 16px;
                    font-family: @font-opensans;
                    color: @color-portlet-link;

                    &:hover,
                    &:focus{

                        color: @black;
                        text-decoration: none;
                        // font-weight: 700;
                    }
                }
            }
        }
    }

    @media (max-width:767px) {
        visibility: hidden;
        height: 0;

    }
}

// site setup icons color
.configlets{

    [class^="icon-"]:before,
    [class*=" icon-"]:before{

        color: @text-info;
    }
}




/* Search Portlet
   ========================================================================== */

.portletSearch{

    form{

        padding: 5px !important;
    }

    .searchField.portlet-search-gadget+.searchButton{
        float: none;
        margin-top: 10px;
    }

}


/* Control Panel Portlets
   ========================================================================== */

.portletSiteSetup {

    nav ul li{
        border-bottom: none ;
    }

    a {
        font-size: 16px;
        text-decoration: none !important;
    }
}

.managedPortletActions{
    margin-top: -15px;
}

.portlet.portletCalendar table{

    width: 99.5%;
    margin-left: 1px;

    td>*{
        margin: 5px 0;
    }

    .weekdays th,
    .event{
        background-color: @white;
    }
}

/* Managed Portlets
   ========================================================================== */

.managedPortlet .portletHeader > a{

    color: @gray-darker;
    font-weight: 400;
}

.managedPortletActions form{

    margin-top: 10px;
}

#portlet-prefs ul ul li{

    font-family: @font-opensans ;

    a:before{
        color: @black-light;
        margin-top: -9px;
    }

    a:hover,
    a:focus,
    a:active{
        // font-weight: 700;
        padding-left: 45px;
        background-color: @white;
    }
}