/*!
 * Start Bootstrap - Business Casual Bootstrap Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

body {
    font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    background: url('../images/bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
    font-family: "Josefin Slab","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
}

p {
    font-size: 1.25em;
    line-height: 1.6;
    color: #000;
}

hr {
    max-width: 400px;
    border-color: #999999;
}

.brand,
.address-bar {
    display: none;
}

.navbar-brand {
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 2px;
}

.navbar-nav {
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 3px;
}

.img-full {
    min-width: 100%;
}

.brand-before,
.brand-name {
    text-transform: capitalize;
}

.brand-before {
    margin: 15px 0;
}

.brand-name {
    margin: 0;
    font-size: 4em;
}

.tagline-divider {
    margin: 15px auto 3px;
    max-width: 250px;
    border-color: #999999;
}

.box {
    margin-bottom: 20px;
    padding: 30px 15px;
    background: #fff;
    background: rgba(255,255,255,0.9);
}

.intro-text {
    text-transform: uppercase;
    font-size: 1.25em;
    font-weight: 400;
    letter-spacing: 1px;
}

.img-border {
    float: none;
    margin: 0 auto 0;
    border: #999999 solid 1px;
}

.img-left {
    float: none;
    margin: 0 auto 0;
}

footer {
    background: #fff;
    background: rgba(255,255,255,0.9);
}

footer p {
    margin: 0;
    padding: 50px 0;
}

@media screen and (min-width:768px) {
    .brand {
        display: inherit;
        margin: 0;
        padding: 30px 0 10px;
        text-align: center;
        text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
        font-family: "Josefin Slab","Helvetica Neue",Helvetica,Arial,sans-serif;
        font-size: 5em;
        font-weight: 700;
        line-height: normal;
        color: #fff;
    }

    .top-divider {
        margin-top: 0;
    }

    .img-left {
        float: left;
        margin-right: 25px;
    }

    .address-bar {
        display: inherit;
        margin: 0;
        padding: 0 0 40px;
        text-align: center;
        text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
        text-transform: uppercase;
        font-size: 1.25em;
        font-weight: 400;
        letter-spacing: 3px;
        color: #fff;
    }

    .navbar {
        border-radius: 0;
    }

    .navbar-header {
        display: none;
    }

    .navbar {
        min-height: 0;
    }

    .navbar-default {
        border: none;
        background: #fff;
        background: rgba(255,255,255,0.9);
    }

    .nav>li>a {
        padding: 35px;
    }

    .navbar-nav>li>a {
        line-height: normal;
    }

    .navbar-nav {
        display: table;
        float: none;
        margin: 0 auto;
        table-layout: fixed;
        font-size: 1.25em;
    }
}

@media screen and (min-width:1200px) {
    .box:after {
        content: '';
        display: table;
        clear: both;
    }
}