/* ==========================================================================
   FOOTER
   ========================================================================== */





#portal-footer-wrapper{

    background-color: @bg-footer;
    color: @gray-darker;

    a{
        color: @color-base-link !important;

        &:hover,
        &:focus,
        &:active{
            color: @color-base-link-hover !important;
        }
    }

    abbr{
        color: @gray-darker;
    }

    .portletWrapper {

        display: block;

        &:first-child{

            border-bottom: 0;
            padding-top: 1.5em;
        }

    }
    .portlet {

        background: transparent;
        border: none;
        box-shadow: none;
        margin-bottom: 0;

        .portletContent{
            text-align: center;
            border-top: 0;

            >*{
                padding: 0;
            }

            >ul>li a{
                padding-bottom: 15px;
            }

            >ul>li a:before{
                content: "";
            }
        }
    }

}